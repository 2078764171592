new WOW({ offset: 200 }).init();


$(document).ready(function () {

	var packagesLink = $('a[href="/packages/"]');
	packagesLink.attr('title', 'Packages');

	if (window.matchMedia('(min-width:768px)').matches) {
		$("#Logo").addClass("wow pulse");
	}


	$("#HomepageGallery .Previous").click(function (e) {
		var img = $("#HomepageGalleryInner div").first();
		img.animate({ width: 0 }, 1000, "linear", function () {
			img.remove();
			$("#HomepageGalleryInner").append(img);
			img.width(170);
		});
		e.preventDefault();
		return false;
	});

	$("#HomepageGallery .Next").click(function (e) {
		var img = $("#HomepageGalleryInner div").last();
		img.width(0);
		img.remove();
		$("#HomepageGalleryInner").prepend(img);
		img.animate({ width: 170 }, 1000, "linear");
		e.preventDefault();
		return false;
	});


	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});
	$("a.has-tooltip").tooltip({
		placement: "auto"
	});


});

